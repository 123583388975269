@font-face {
  font-family: 'Geogrotesque';
  src: url('./images/fonts/Geogrotesque-Regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('./images/fonts/Geogrotesque-SemiBold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
}